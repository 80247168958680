/* eslint-disable no-unused-vars */
import React from 'react'
import { StyleCheckbox } from './style'
import classNames from 'classnames'

interface Props {
  name: string
  wrapperClassName?: string
  className?: string
  label: string
  checked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  hideLabel?: boolean
  disabled?: boolean
}

const Checkbox = ({
  wrapperClassName,
  className,
  label,
  checked,
  onChange,
  hideLabel,
  disabled,
  name,
}: Props) => {
  const rootCls = classNames(wrapperClassName, {
    disabled: disabled === true,
  })
  return (
    <StyleCheckbox className={rootCls}>
      <input
        className={className ? `checkbox ${className}` : `checkbox`}
        type='checkbox'
        name={name}
        id={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={name} className={hideLabel === true ? 'sr-only' : `text-dark-80`}>
        {label}
      </label>
    </StyleCheckbox>
  )
}

export default Checkbox
